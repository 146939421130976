import React, { useEffect } from "react";
import IndexLayout from "../layouts";
import { navigate } from "gatsby";

const ResearchPapers = () => {
  useEffect(() => {
    navigate("https://blog.skodel.com/");
  }, []);
  return <IndexLayout />;
};

export default ResearchPapers;
